/* eslint-disable */
import { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

interface AuthResponse {
  credential: string
}

interface AuthUserData {
  token?: string
  message?: string
}

interface UseAuthReturn {
  loading: boolean
  error: string
  handleAuth: (
    response: AuthResponse,
    ssoProvider: 'google' | 'microsoft' | 'apple'
  ) => Promise<void>
}

const useAuths = (url: string): UseAuthReturn => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleAuth = async (
    response: AuthResponse,
    ssoProvider: 'google' | 'microsoft' | 'apple'
  ): Promise<void> => {
    setLoading(true)
    setError('') // Reset error before a new request
    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          credential: response.credential,
          ssoProvider,
        }),
      })

      const contentType = res.headers.get('Content-Type')
      let data: AuthUserData = { message: 'Unexpected response format' }

      if (contentType && contentType.includes('application/json')) {
        data = await res.json()
      } else {
        data.message = 'Received non-JSON response'
      }

      setLoading(false)

      if (res.status === 400) {
        toast.error('User not found')
      } else if (res.ok && data?.token) {
        localStorage.setItem('token', data.token)
        window.location.reload()
      } else {
        throw new Error(data?.message || 'Authentication failed')
      }
    } catch (error: any) {
      setLoading(false)
      setError(error.message)
      toast.error(error.message)
    }
  }

  return { loading, error, handleAuth }
}

export default useAuths
