import ReactDOM from 'react-dom/client'
import 'antd/dist/antd.css'
import './index.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { msalConfig } from './config/authConfig'
import App from './App'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
// import { isServiceWorkerExists, onServiceWorkerLoad } from './utils/serviceWorkerUtil'
import LayoutThemeProvider from './context/LayoutThemeContext'

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <LayoutThemeProvider>
        <App />
      </LayoutThemeProvider>
    </GoogleOAuthProvider>
  </MsalProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// const loginToken = localStorage.getItem('token')
/* const asyncCallServiceWorker = async () => {
  const hasServiceWorker = await isServiceWorkerExists()

  if (loginToken || hasServiceWorker) {
    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerLoad,
    })
  }
}

//asyncCallServiceWorker() */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
